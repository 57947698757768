import { useEffect, useRef, useState } from "react";
import { ConvertDateToString, ConvertUnixTimestampToYYYYMMDD, getCurrentDate } from "../../../app/common";
import { Select, Option, Input, Button, Alert, Typography } from "@material-tailwind/react";
import { CreateUserDataRequest, GetUserResponse } from "../accountModel";
import { useTranslation } from "react-i18next";
import { ValidateBirthDate, ValidatePhoneNumber } from "../../../app/validation";
import i18n from "../../../i18n";

interface AccountFormProps {
  isEdit: boolean,
  isUserChanged: boolean,
  userData?: GetUserResponse,
  currentUserData?: CreateUserDataRequest,
  buttonText: string,
  onClickFunction: (userData: CreateUserDataRequest) => void
}

function AccountFormComponent(props: AccountFormProps) {
  const countryNameMinLength: number = 4;
  const [errorMesssage, setErrorMesssage] = useState("");
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const emailRef = useRef("fakeEmail23@hotmail.com");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const [birthDate, setBirthDate] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState<string | undefined>("");
  // const [city, setCity] = useState("");
  // const [street, setStreet] = useState("");
  // const [houseNumber, setHouseNumber] = useState("");
  // const [postalCode, setPostalCode] = useState("");
  // const [country, setCountry] = useState("");

  // const currentDateTime = getCurrentDate();
  // currentDateTime.setFullYear(currentDateTime.getFullYear() - 100);
  // const minDate = ConvertDateToString(currentDateTime);
  // currentDateTime.setFullYear(currentDateTime.getFullYear() + 84);
  // const maxDate = ConvertDateToString(currentDateTime);

  var genderOptions = [t("Male"), t("Female")];
  const genderServerOptions = ["Male", "Female"];

  useEffect(() => {
    let indexOfGender = 0
    if (gender !== undefined) {
      indexOfGender = genderOptions.indexOf(gender)
    }
    let shownTranslatedGender = genderOptions[indexOfGender]

    setGender(shownTranslatedGender)
    genderOptions = [];
    genderOptions = [t("Male"), t("Female")];
  }, [i18n.language])

  useEffect(() => {
    if (props.userData?.email && !props.isUserChanged) {
      let indexOfGender = genderServerOptions.indexOf(props.userData.gender)
      let shownTranslatedGender = genderOptions[indexOfGender]

      setFirstname(props.userData.firstName)
      setLastname(props.userData.lastName)
      // setBirthDate(props.userData.birthDate)
      // setPhoneNumber(props.userData.phoneNumber)

      setGender(shownTranslatedGender)
      // setCity(props.userData.city)
      // setStreet(props.userData.street)
      // setHouseNumber(props.userData.houseNumber)
      // setPostalCode(props.userData.postalCode)
      // setCountry(props.userData.country)
    }
    else {
      if (props.currentUserData?.Email) {
        let indexOfGender = genderServerOptions.indexOf(props.currentUserData.Gender)
        let shownTranslatedGender = genderOptions[indexOfGender]

        setFirstname(props.currentUserData.FirstName)
        setLastname(props.currentUserData.LastName)
        // setBirthDate(props.currentUserData.BirthDate)
        // setPhoneNumber(props.currentUserData.PhoneNumber)

        setGender(shownTranslatedGender)
        // setCity(props.currentUserData.City)
        // setStreet(props.currentUserData.Street)
        // setHouseNumber(props.currentUserData.HouseNumber)
        // setPostalCode(props.currentUserData.PostalCode)
        // setCountry(props.currentUserData.Country)
      }
    }
  }, [])

  const onButtonClick = () => {
    let indexOfGender = 0
    if (gender !== undefined) {
      indexOfGender = genderOptions.indexOf(gender)
    }

    let serviceGender = genderServerOptions[indexOfGender]

    let userData: CreateUserDataRequest = {
      Email: emailRef.current,
      FirstName: firstname,
      LastName: lastname,
    //  BirthDate: props.isEdit ? ConvertUnixTimestampToYYYYMMDD(birthDate) : birthDate,
    //  PhoneNumber: phoneNumber,
      Gender: serviceGender,
      // City: city,
      // Street: street,
      // HouseNumber: houseNumber,
      // PostalCode: postalCode,
      // Country: country
    }

    let isValid = ValidateUser(userData);
    if (isValid) {
      props.onClickFunction(userData);
    }
  }

  const ValidateUser = (userData: CreateUserDataRequest) => {
    if (userData.FirstName && userData.LastName && userData.Gender
      // && userData.BirthDate  && userData.PhoneNumber && userData.HouseNumber && userData.Street && userData.City && userData.Country && userData.PostalCode
      ) 
      {
        return true;
      // if (!ValidateEmail(userData.Email)) {
      //   setErrorMesssage(t("InvalidEmail"));
      //   setOpen(true);
      // }
     // if (!ValidateBirthDate(birthDate, minDate, maxDate)) {
      //   if (userData.BirthDate < minDate) {
      //     setErrorMesssage(t("PleaseInputAValidBirthdate"));
      //     setOpen(true);
      //   }
      //   if (userData.BirthDate > maxDate) {
      //     setErrorMesssage(t("MinimumAgeRequirement"));
      //     setOpen(true);
      //   }
      // }
      // if (!ValidatePhoneNumber(userData.PhoneNumber)) {
      //   setErrorMesssage(t("PhoneRequirements"));
      //   setOpen(true);
      // }
      // //check for country length and numbers in it
      // if (userData.Country.length < countryNameMinLength) {
      //   setErrorMesssage(t("CountryMustContainAtLeast4Letters"));
      //   setOpen(true);
      // }
      // if (/\d/.test(userData.Country)) {
      //   setErrorMesssage(t("CountryCannotIncludeNum"));
      //   setOpen(true);
      // }
      // else {
      //   return true;
      // }
    }
    else {
      setErrorMesssage(t("InsertAllTheData"));
      setOpen(true);
    }
    return false;
  }

  const backToProfile = () => {
    if (props.userData) {
      let indexOfGender = 0
      if (gender !== undefined) {
        indexOfGender = genderOptions.indexOf(gender)
      }

      let serviceGender = genderServerOptions[indexOfGender]

      let userData: CreateUserDataRequest = {
        Email: props.userData.email,
        FirstName: props.userData.firstName,
        LastName: props.userData.lastName,
       // BirthDate: props.isEdit ? ConvertUnixTimestampToYYYYMMDD(props.userData.birthDate) : props.userData.birthDate,
       // PhoneNumber: props.userData.phoneNumber,
        Gender: serviceGender,
        // City: props.userData.city,
        // Street: props.userData.street,
        // HouseNumber: props.userData.houseNumber,
        // PostalCode: props.userData.postalCode,
        // Country: props.userData.country
      }
      props.onClickFunction(userData);
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-[278px] lg:w-[352px] 2xl:w-[450px] space-y-5">
        {errorMesssage !== "" ? (
          <Alert color="red" open={open} onClose={() => setOpen(false)}>
            <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">{errorMesssage}</Typography>
          </Alert>
        ) : (
          <></>
        )}
        {props.isEdit ?
          <Button id="backToProfileBtn" size="sm" className="flex items-center cursor-pointer bg-transparent shadow-none hover:shadow-none p-0" onClick={backToProfile}>
            <svg className="w-[6px] h-[11px]" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 6 11" fill="none">
              <path d="M0.141703 5.84268L5.23331 10.8693C5.31827 10.9532 5.43114 11 5.54851 11C5.66589 11 5.77875 10.9532 5.86371 10.8693L5.86919 10.8636C5.91052 10.8229 5.94343 10.7739 5.96592 10.7197C5.9884 10.6654 6 10.607 6 10.5479C6 10.4888 5.9884 10.4304 5.96592 10.3761C5.94343 10.3218 5.91052 10.2729 5.86919 10.2322L1.07451 5.49905L5.86919 0.767807C5.91052 0.727127 5.94343 0.67816 5.96592 0.623888C5.9884 0.569615 6 0.511169 6 0.452106C6 0.393043 5.9884 0.334597 5.96592 0.280324C5.94343 0.226051 5.91052 0.177086 5.86919 0.136406L5.86371 0.130726C5.77875 0.0468118 5.66588 -2.47664e-07 5.54851 -2.42533e-07C5.43114 -2.37403e-07 5.31827 0.0468118 5.23331 0.130726L0.141703 5.15732C0.096924 5.20153 0.0612753 5.25469 0.0369175 5.3136C0.0125596 5.3725 -2.43214e-07 5.43592 -2.40413e-07 5.5C-2.37611e-07 5.56408 0.0125597 5.6275 0.0369175 5.6864C0.0612753 5.74531 0.096924 5.79848 0.141703 5.84268Z" fill="#333333" />
            </svg>
            <Typography className="underline lowercase text-sm font-normal tracking-[0.7px] ml-[19px] text-#2C2C2C">
              {t("BackToProfile")}
            </Typography>
          </Button> : <></>
        }
        {/* <Input id="email" label={t("Email")} color="orange" value={email} onChange={(event) => setEmail(event.target.value)} readOnly={props.userData?.email ? true : false} required className={`${props.userData?.email ? 'pointer-events-none' : ''}`} /> */}
        <Input id="firstName" label={t("FirstName")} color="orange" value={firstname} onChange={(event) => setFirstname(event.target.value)} required />
        <Input id="lastName" label={t("LastName")} color="orange" value={lastname} onChange={(event) => setLastname(event.target.value)} required />
        {/* <Input id="date" type="date" label={t("BirthDate")} color="orange" min={minDate} max={maxDate} value={ConvertUnixTimestampToYYYYMMDD(birthDate)} onChange={(event) => setBirthDate(event.target.value)} required />
        <Input id="tel" type="tel" label={t("PhoneNumber")} color="orange" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} required /> */}
        <Select id="gender" label={t("Gender")} color="orange" value={gender} onChange={(choice) => setGender(choice)}>
          {genderOptions.map((genderOption, index) => (
            <Option id={index.toString() + genderOption} key={index} value={genderOption}>{genderOption}</Option>
          ))}
        </Select>
        {/* <Input id="street" label={t("Street")} color="orange" value={street} onChange={(event) => setStreet(event.target.value)} required />
        <Input id="houseNumber" label={t("HouseNumber")} color="orange" value={houseNumber} onChange={(event) => setHouseNumber(event.target.value)} required />
        <Input id="city" label={t("City")} color="orange" value={city} onChange={(event) => setCity(event.target.value)} required />
        <Input id="postalCode" label={t("PostalCode")} color="orange" value={postalCode} onChange={(event) => setPostalCode(event.target.value)} required />
        <Input id="country" label={t("Country")} color="orange" value={country} onChange={(event) => setCountry(event.target.value)} required /> */}
        <Button id="formBtn" onClick={onButtonClick} className="hover:bg-#FF6104 bg-#FF8136 h-[40px] w-full shadow-none hover:shadow-none">{props.buttonText}</Button>
      </div>
    </div>
  );
};

export default AccountFormComponent;
