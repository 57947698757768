import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { CreateUserDataRequest } from "../accountModel";
import { GetUser, PostCreateUserData, resetStatusCode } from "../accountSlice";
import { setShouldRefreshToken } from "../../Authentification/authentificationSlice";
import { LoadingPage } from "../../CommonPages/loadingPage";
import { useTranslation } from "react-i18next";
import AccountFormComponent from "./accountFormComponent";
import { Card, CardBody, Dialog, Typography } from "@material-tailwind/react";
import SuccessCard from "../../CommonPages/successCard";

export function CreateUserData() {
    const { t } = useTranslation();

    const [messsage, setMesssage] = useState("");
    const [success, setSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const userDataRef = useRef<CreateUserDataRequest>();

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const accountData = useSelector((state: RootState) => state.AccountSlice);
    const logInData = useSelector((state: RootState) => state.AuthentificationSlice);

    const onCreate = (userData: CreateUserDataRequest) => {
        if (userData.FirstName && userData.LastName && userData.Gender
           // && userData.BirthDate  && userData.PhoneNumber && userData.HouseNumber && userData.Street && userData.City && userData.Country && userData.PostalCode
        ) {
            userDataRef.current = userData;
            dispatch(PostCreateUserData(userData))
        }
        else {
            setMesssage(t("InsertAllTheData"));
            setOpenDialog(true);
        }
    }

    useEffect(() => {
        if (accountData.shouldCreateData === false && accountData.accountSliceData.statusValues.error !== null) {
            if (accountData.accountSliceData.accountData.loggedUserData.firstName === '') {
                dispatch(GetUser());
            }
            navigate('/');
        }
        else if (accountData.accountSliceData.statusValues.statusCode === '401') {
            dispatch(setShouldRefreshToken())
        }
    }, [accountData]);

    useEffect(() => {
        // if (logInData.shouldRefresh) {
        //     dispatch(postRefreshAccessToken())
        // }
        //else
        if (logInData.shouldRedirectToLogin) {
            navigate("/login");
        }
        else if (logInData.repeatLastAction)  // this thing should be disscused and tested
        {
            if (userDataRef.current) {
                dispatch(PostCreateUserData(userDataRef.current))
            }
        }
    }, [logInData])

    useEffect(() => {
        if (accountData.accountSliceData.statusValues.statusCode === '200' || accountData.accountSliceData.statusValues.statusCode === 'OK') {
            setSuccess(true);
            setMesssage(t("AccountCreated"));
            handleOpenDialog();
        }
        else if (accountData.accountSliceData.statusValues.statusCode !== '') {
            setSuccess(false);
            setMesssage(accountData.accountSliceData.statusValues.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
        dispatch(resetStatusCode());
    }, [accountData.accountSliceData.statusValues.statusCode])

    if (accountData.accountSliceData.statusValues.isLoading) {
        return (LoadingPage(accountData.accountSliceData.statusValues.isLoading))
    }

    return (
        <div className="flex justify-center m-[5%]">
            <form>
                <Card className="w-[320px] lg:w-[400px] 2xl:w-[500px] h-fit px-[20px] lg:px-[25px] shadow-4s">
                    <CardBody className="p-0">
                        <Typography className="uppercase mt-[40px] text-xl 2xl:text-2xl font-bold text-#333333 text-center">
                            {t("CreateAccount")}
                        </Typography>
                        <div className="mt-[28px] mb-[35px]">
                            <AccountFormComponent isEdit={false} isUserChanged={false} buttonText={t("Create")} onClickFunction={onCreate} userData={accountData.accountSliceData.accountData.loggedUserData} currentUserData={userDataRef.current} />
                        </div>
                    </CardBody>
                </Card>
            </form>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    );
}