import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { postActivateAccount } from "../authentificationSlice";
import { AccountActivation } from "../authModel";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { ValidateEmail } from "../../../app/validation";
import { LoadingPage } from "../../CommonPages/loadingPage";
import ActivateAccountCard from "./activateAccountCard";
import { Alert, Dialog, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import SuccessCard from "../../CommonPages/successCard";

export function ActivateAccount() {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [messsage, setMesssage] = useState("");
    const [success, setSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const activateAccountData = useSelector((state: RootState) => state.AuthentificationSlice);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        submitFunction(email);
    };

    const submitFunction = (email: string) => {
        if (ValidateEmail(email)) {
            let code = params.get("code");
            const activateAccountReq: AccountActivation = {
                username: email,
                code: code ?? "",
            };
            dispatch(postActivateAccount(activateAccountReq));
        } else {
            setMesssage(t("YouHaveEnteredAnInvalidEmailAddress"));
            setOpen(true);
        }
    };

    useEffect(() => {
        if (activateAccountData.statusCode === '200' || activateAccountData.statusCode === 'OK') {
            setSuccess(true);
            setMesssage(t("AccountIsActivated"));
            handleOpenDialog();
        }
        else if (activateAccountData.statusCode !== '') {
            setSuccess(false);
            setMesssage(activateAccountData.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
    }, [activateAccountData.statusCode])

    useEffect(() => {
        if (openDialog === false && success === true && (activateAccountData.statusCode === '200' || activateAccountData.statusCode === 'OK')) {
            navigate('/login');
        }

    }, [openDialog])

    if (activateAccountData.isLoading) {
        return (LoadingPage(activateAccountData.isLoading))
    }

    return (
        <div className="flex justify-center m-[5%]">
            <form className="space-y-5" onSubmit={handleSubmit}>
                {messsage !== "" ? (
                    <Alert color="red" open={open} onClose={() => setOpen(false)}>
                        <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">
                            {messsage}
                        </Typography>
                    </Alert>
                ) : (<></>)}
                <ActivateAccountCard onClickFunction={submitFunction} setEmailForm={setEmail} />
                <input type="submit" style={{ display: "none" }} />
            </form>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    );
}
