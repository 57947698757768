import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { getCurrentDate } from "../app/common";
import { useEffect } from "react";
import ScrollSpy from "../UIComponents/ScrollSpy/ScrollSpy";

interface ImprintCompanyData {
    id: string;
    name: string;
    address: string;
    postalCodeAndCity: string;
    country: string;
    businessPurpose: string;
    registernumber: string;
    taxId: string;
    registerCourt: string;
    headQuarters: string;
    headQuartersPhone: string;
    headQuartersEmail: string;
    memberOf: string;
    professionalLaw: string;
    jobTitle: string;
    awardingState: string;
    managingDirector: string;
}

interface ScrollSpySection {
    id: string;
    label: string;
}

function Imprint() {
    const { t } = useTranslation();

    const companies: ScrollSpySection[] = [
        // { id: "austria", label: "BIMEXPERTS GmbH" },
        // { id: "", label: "" },
        { id: "austriaBTools", label: "BTools GmbH" },
        // { id: "", label: "" },
        // { id: "germany", label: "BIMEXPERTS GmbH" },
        // { id: "", label: "" },
        // { id: "serbia", label: "BIMEXPERTS d.o.o." },
        // { id: "", label: "" },
        // { id: "bulgaria", label: "BIMEXPERTS EOOD" },
    ];

    const companiesData: ImprintCompanyData[] = [
        // {
        //     id: "austria",
        //     name: t("nameAT"),
        //     address: t("addressAT"),
        //     postalCodeAndCity: t("postalCodeAndCityAT"),
        //     country: t("countryAT"),
        //     businessPurpose: t("businessPurposeAT"),
        //     registernumber: t("registernumberAT"),
        //     taxId: t("taxIdAT"),
        //     registerCourt: t("registerCourtAT"),
        //     headQuarters: t("headQuartersAT"),
        //     headQuartersPhone: "+43 (0)1 6022222",
        //     headQuartersEmail: "austria@bimexperts.com",
        //     memberOf: t("memberOfAT"),
        //     professionalLaw: t("professionalLawAT"),
        //     jobTitle: t("jobTitleAT"),
        //     awardingState: t("awardingStateAT"),
        //     managingDirector: t("managingDirectorAT"),
        // },
        {
            id: "austriaBTools",
            name: t("nameATBTools"),
            address: t("addressATBTools"),
            postalCodeAndCity: t("postalCodeAndCityATBTools"),
            country: t("countryATBTools"),
            businessPurpose: t("businessPurposeATBTools"),
            registernumber: t("registernumberATBTools"),
            taxId: t("taxIdATBTools"),
            registerCourt: t("registerCourtATBTools"),
            headQuarters: t("headQuartersATBTools"),
            headQuartersPhone: "+43 (0)1 6022222",
            headQuartersEmail: "austria@btools.at",
            memberOf: t("memberOfATBTools"),
            professionalLaw: t("professionalLawATBTools"),
            jobTitle: t("jobTitleATBTools"),
            awardingState: t("awardingStateATBTools"),
            managingDirector: t("managingDirectorATBTools"),
        },
        // {
        //     id: "germany",
        //     name: t("nameDE"),
        //     address: t("addressDE"),
        //     postalCodeAndCity: t("postalCodeAndCityDE"),
        //     country: t("countryDE"),
        //     businessPurpose: t("businessPurposeDE"),
        //     registernumber: t("registernumberDE"),
        //     taxId: t("taxIdDE"),
        //     registerCourt: t("registerCourtDE"),
        //     headQuarters: t("headQuartersDE"),
        //     headQuartersPhone: "+49 9443 49590 40 ",
        //     headQuartersEmail: "germany@bimexperts.com",
        //     memberOf: t("memberOfDE"),
        //     professionalLaw: t("professionalLawDE"),
        //     jobTitle: t("jobTitleDE"),
        //     awardingState: t("awardingStateDE"),
        //     managingDirector: t("managingDirectorDE"),
        // },
        // {
        //     id: "serbia",
        //     name: t("nameSRB"),
        //     address: t("addressSRB"),
        //     postalCodeAndCity: t("postalCodeAndCitySRB"),
        //     country: t("countrySRB"),
        //     businessPurpose: t("businessPurposeSRB"),
        //     registernumber: t("registernumberSRB"),
        //     taxId: t("taxIdSRB"),
        //     registerCourt: t("registerCourtSRB"),
        //     headQuarters: t("headQuartersSRB"),
        //     headQuartersPhone: "+381 21 3832551 ",
        //     headQuartersEmail: "serbia@bimexperts.com",
        //     memberOf: t("memberOfSRB"),
        //     professionalLaw: t("professionalLawSRB"),
        //     jobTitle: t("jobTitleSRB"),
        //     awardingState: t("awardingStateSRB"),
        //     managingDirector: t("managingDirectorSRB"),
        // },
        // {
        //     id: "bulgaria",
        //     name: t("nameBG"),
        //     address: t("addressBG"),
        //     postalCodeAndCity: t("postalCodeAndCityBG"),
        //     country: t("countryBG"),
        //     businessPurpose: t("businessPurposeBG"),
        //     registernumber: t("registernumberBG"),
        //     taxId: t("taxIdBG"),
        //     registerCourt: t("registerCourtBG"),
        //     headQuarters: t("headQuartersBG"),
        //     headQuartersPhone: "+381 21 3832551 ",
        //     headQuartersEmail: "bulgaria@bimexperts.com",
        //     memberOf: t("memberOfBG"),
        //     professionalLaw: t("professionalLawBG"),
        //     jobTitle: t("jobTitleBG"),
        //     awardingState: t("awardingStateBG"),
        //     managingDirector: t("managingDirectorBG"),
        // },
    ]

    useEffect(() => {
        ReactGA.send({ hitType: "PageView", page: window.location.pathname + window.location.search, title: getCurrentDate() });
    }, [])

    return (<>
        <div className="mb-5">
            <Typography className="uppercase bg-#F6F6F6 font-bold text-lg lg:text-2xl 2xl:text-3xl text-center py-[20px] break-words">
                {t("Imprint")}
            </Typography>
            <div className="px-[30px] lg:px-[100px]">
                <ScrollSpy companies={companies} companyData={companiesData} />
            </div>
        </div>
    </>);
}

export default Imprint;