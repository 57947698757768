import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface ScrollSpySection {
    id: string;
    label: string;
}

interface ImprintCompanyData {
    id: string;
    name: string;
    address: string;
    postalCodeAndCity: string;
    country: string;
    businessPurpose: string;
    registernumber: string;
    taxId: string;
    registerCourt: string;
    headQuarters: string;
    headQuartersPhone: string;
    headQuartersEmail: string;
    memberOf: string;
    professionalLaw: string;
    jobTitle: string;
    awardingState: string;
    managingDirector: string;
}

interface ScrollSpyProps {
    companies: ScrollSpySection[];
    companyData: ImprintCompanyData[];
}

const ScrollSpy = (props: ScrollSpyProps) => {
    const { t } = useTranslation();

    const [activeItem, setActiveItem] = useState<string>(props.companies[0].id);

    const handleScroll = useCallback(() => {
        const sectionElements = props.companies.map((section) =>
            document.getElementById(section.id)
        );

        let currentActiveId = "";

        sectionElements.forEach((section) => {
            if (section) {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight / 6 && rect.bottom >= window.innerHeight / 6) {
                    currentActiveId = section.id;
                }
            }
        });

        if (currentActiveId && currentActiveId !== activeItem) {
            setActiveItem(currentActiveId);
        }
    }, [props.companies, activeItem]);

    const handleClick = (id: string) => {
        if (id == "" || activeItem == id) return;
        setActiveItem(id);
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 120,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="flex mt-[40px] mb-[120px]">
            {/* Left Side */}
            <div className="w-1/3 sticky top-[120px] h-full max-h-screen overflow-auto">
                <ul className="text-start text-[18px] text-[#333333]">
                    {props.companies.map((item, index) => (
                        <li key={index + item.id} onClick={() => handleClick(item.id)}
                            className={`relative py-[12px] px-[20px] border-l-2 ${item.id !== "" && activeItem === item.id ? "font-bold text-[#FF6104] border-[#FF6104]" : "border-[#D3D3D3]"}`}>
                            {index > 0 && (
                                <span className="absolute left-0 -top-2 h-2 bg-[#D3D3D3]"></span>)}
                            <a href={`#${item.id}`} className="block">{item.label}</a>
                        </li>
                    ))}
                </ul>
            </div>
            {/* Right Side */}
            <div className="w-2/3 flex flex-col items-start text-start gap-[40px]">
                {props.companyData.map((company, index) => (company.id !== "" &&
                    <div key={index + company.id} className="text-#333333 text-[16px] space-y-[5px]">
                        <p id={company.id} className="text-[38px] font-bold pb-[15px]">{company.name}</p>
                        <p className="">{company.address},</p>
                        <p className="">{company.postalCodeAndCity},</p>
                        <p className="">{company.country}</p>
                        <p className="flex gap-[5px] pt-[15px]">
                            <span className="font-bold">{t("businessPurpose")}:</span>
                            <span>{company.businessPurpose}</span>
                        </p>
                        <p className="flex gap-[5px]">
                            <span className="font-bold">{t("registernumber")}:</span>
                            <span className="">{company.registernumber}</span>
                        </p>
                        <p className="flex gap-[5px]">
                            <span className="font-bold">{t("taxID")}:</span>
                            <span className="">{company.taxId}</span>
                        </p>
                        <p className="flex gap-[5px]">
                            <span className="font-bold">{t("registerCourt")}:</span>
                            <span className="">{company.registerCourt}</span>
                        </p>
                        <p className="flex gap-[5px]">
                            <span className="font-bold">{t("headQuarters")}:</span>
                            <span className="">{company.headQuarters}</span>
                        </p>
                        <ul className="flex flex-col ml-10">
                            <li className="flex items-center">
                                <span className="w-2 h-2 bg-#FF6104 rounded-full flex-shrink-0 mr-4"></span>
                                <a href={"tel:" + company.headQuartersPhone} className="hover:text-#FF6104 cursor-pointer">{company.headQuartersPhone}</a>
                            </li>
                            <li className="flex items-center">
                                <span className="w-2 h-2 bg-#FF6104 rounded-full flex-shrink-0 mr-4"></span>
                                <a href={"mailto:" + company.headQuartersEmail} className="hover:text-#FF6104 cursor-pointer">{company.headQuartersEmail}</a>
                            </li>
                        </ul>
                        <p className="flex gap-[5px] pt-[15px]">
                            <span className="font-bold">{t("memberOf")}:</span>
                            <span className="">{company.memberOf}</span>
                        </p>
                        <p className="flex gap-[5px]">
                            <span className="font-bold">{t("professionalLaw")}:</span>
                            <span className="">{company.professionalLaw}</span>
                        </p>
                        <p className="flex gap-[5px] pt-[15px]">
                            <span className="font-bold">{t("jobTitle")}:</span>
                            <span className="">{company.jobTitle}</span>
                        </p>
                        <p className="flex gap-[5px]">
                            <span className="font-bold">{t("awardingState")}:</span>
                            <span className="">{company.awardingState}</span>
                        </p>
                        <p className="flex flex-col pt-[15px]">
                            <span className="font-bold">{t("managingDirector")}:</span>
                            <span className="">{company.managingDirector}</span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ScrollSpy;
