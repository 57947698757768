import { CreateUserDataRequest, GetUserResponse } from "../features/Accounts/accountModel";
import { GetDirectoryMembersResponse } from "../features/Directories/directoryModel";
import { CyrrencyOptions } from "../features/stripe/stripeModel";

export function CompareUserData(currentUserData: GetUserResponse, newUserData: CreateUserDataRequest) {
    if (newUserData.FirstName !== currentUserData.firstName ||
        newUserData.LastName !== currentUserData.lastName ||
       // newUserData.BirthDate !== ConvertUnixTimestampToYYYYMMDD(currentUserData.birthDate) ||
        newUserData.Gender !== currentUserData.gender 
        // || newUserData.PhoneNumber !== currentUserData.phoneNumber ||
        // newUserData.Country !== currentUserData.country ||
        // newUserData.City !== currentUserData.city ||
        // newUserData.PostalCode !== currentUserData.postalCode ||
        // newUserData.Street !== currentUserData.street ||
        // newUserData.HouseNumber !== currentUserData.houseNumber
    ) {
        return true;
    }
    else {
        return false;
    }
}

export function GetUserFullName(userData: GetDirectoryMembersResponse | undefined): string {
    if (userData === undefined) {
        return "";
    }
    else {
        return userData.firstName + " " + userData.lastName;
    }
}

export function ConvertUnixTimestampToYYYYMMDD(unixTimestamp: string): string {
    if (unixTimestamp.toString().includes('-')) {
        return unixTimestamp;
    }
    const timestampInMilliseconds = parseInt(unixTimestamp) * 1000;
    const date = new Date(timestampInMilliseconds);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
}

export function ConvertUnixTimestampToDate(unixTimestamp: string): string {
    const timestampInMilliseconds = parseInt(unixTimestamp) * 1000;
    const date = new Date(timestampInMilliseconds);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
}

export function ConvertFullDateTimeToDate(fullDateTime: string): string {
    const date = new Date(fullDateTime);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
}

export function getCurrentDate() {

    return new Date();
}

export function getCurrentDateSeparate() {

    let newDate = getCurrentDate()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return [date, month, year]
}

export function ConvertDateToString(convertDate: Date, separator: string = '-') {
    let date = convertDate.getDate();
    let month = convertDate.getMonth();
    let year = convertDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
}

export function RemoveItemFromArray<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export interface RejectedValue {
    message: string;
    status?: number;
}

export interface StatusValue {
    statusCode: string,
    isLoading: boolean,
    error: string,
}

export enum AppRoles {
    Owner = "Owner",
    Admin = "Admin",
    Member = "Member",
}

export enum UserRole {
    RegularUser = "RegularUser",
    BlogAdmin = "BlogAdmin",
    CompanyAdmin = "CompanyAdmin"
}

export interface PolicyModel {
    mainTitle: string,
    terms: TermsModel[]
}

export interface TermsModel {
    title: string,
    points: string[]
}

export const templateColorData = ['#FF9E00', '#0092B5', '#666666'];

export function GetBrowserLanguage() {
    let language = (navigator.languages ? navigator.languages[0] : (navigator.language || 'en')).toLowerCase();
    if (!ValidLocales.includes(language)) {
        language = 'en';
    }
    return language;
}

export function GetUserLocale(userLanguage: string) {
    let language = userLanguage;
    if (!ValidLocales.includes(userLanguage)) {
        language = 'en';
    }
    return language;
}

export function GetUserCurrency(userCurrency: string) {
    let currency = userCurrency;
    if (!ValidCurrencies.includes(currency)) {
        currency = 'eur';
    }
    return currency;
}

export function GetCurrencyIndex(cyrrencyOptions: CyrrencyOptions[], userCurrency: string) {
    let currency = GetUserCurrency(userCurrency);
    let index = cyrrencyOptions.findIndex(x => x.currency === currency);
    return index !== undefined && index !== -1 ? index : 0;
}

export function GetFormattedPrice(unitAmount: string, currency: string) {
    if (currency === "$") {
        return currency + unitAmount
    }
    else if (currency === "€") {
        return unitAmount + currency
    }
    return ""
}

export function IsGuidEmpty(guid: string) {
    if (guid === "00000000-0000-0000-0000-000000000000" || guid === "" || guid === undefined || guid === null)
        return true;
    return false;
}

const ValidLocales =
    [
        "bg",
        "hr",
        "cs",
        "da", "nl", "en", "et", "fi", "fil", "fr", "de", "el", "hu", "id", "it", "ja", "ko", "lv", "lt", "ms", "mt", "nb", "pl", "pt", "ro", "ru", "zh", "sk", "sl", "es", "sv", "th", "tr", "vi"
    ];
const ValidCurrencies =
    [
        "usd",
        "aed",
        "afn",
        "all", "amd", "ang", "aoa", "ars", "aud", "awg", "azn", "bam", "bbd", "bdt", "bgn", "bif", "bmd", "bnd", "bob", "brl", "bsd", "bwp", "byn", "bzd", "cad", "cdf", "chf", "clp", "cny", "cop", "crc", "cve", "czk", "djf", "dkk", "dop", "dzd", "egp", "etb",
        "eur", "fjd", "fkp", "gbp", "gel", "gip", "gmd", "gnf", "gtq", "gyd", "hkd", "hnl", "htg", "huf", "idr", "ils", "inr", "isk", "jmd", "jpy", "kes", "kgs", "khr", "kmf", "krw", "kyd", "ktz", "lak", "lbp", "lkr", "lrd", "lsl", "mad", "mdl", "mga", "mkd", "mmk", "mop", "mro", "mur", "mvr", "mwk", "mxn", "myr", "mzn", "nad", "ngn", "nio", "nok", "npr", "nzd", "pab", "pen", "pgk", "php", "pkr", "pln", "pyg", "qar", "ron", "rsd", "rub", "rwf", "sar", "sbd", "scr", "sek", "sgd", "shp", "sle", "sll", "sos", "srd", "std", "stl", "thb", "tjs", "top", "try", "ttd", "twd", "tzs", "uah", "ugx", "uyu", "uzs", "vnd", "vuv", "wst", "xaf", "xcd", "xof", "xpf", "yer", "zar", "zmw"
    ];

export const productInfos = [
    {
        id: 'TGA',
        tittle: 'TGA - The Ultimate DWG and PDF Drawing Tool',
        description: [
            'TGA is a powerful drawing application that allows you to easily create and edit 2D drawings while utilizing DWG and PDF files. With this application, you can import your existing drawings and start editing them in no time. Not only that, TGA also comes with a Roombook feature that allows you to create detailed room schedules with ease.',
            'In addition to its powerful drawing tools, TGA also comes with export capabilities that allow you to export your drawings to DWG, PDF, and Excel formats. This means that you can easily share your drawings with others, no matter what software they are using.',
            'Whether you are an architect, engineer, or designer, TGA is the perfect tool for creating and editing 2D drawings. With its intuitive interface and powerful features, you can focus on your design work without worrying about the technical details. Try TGA today and start drawing with ease!'
        ],
        videoId: '8RzyNoIQCpM'
    },
    {
        id: 'ZeitOn',
        tittle: 'ZeitOn - The Ultimate Web-Based Time Management Tracking Tool',
        description: [
            'ZeitOn is a powerful web-based application designed to help you manage your work time and track your absences with ease. With ZeitOn, you can handle every type of request that a working person has, from vacation and sick leave to overtime and remote work requests.',
            'This powerful tool is easy to use, and all you need to do is log in to get started. ZeitOns intuitive interface allows you to quickly and easily manage your time, request time off, and track your absences. You can also set up custom workflows for approvals, so your requests are always handled in a timely and efficient manner.',
            'ZeitOn is also designed to be flexible and adaptable to your needs. Whether you work in an office or remotely, ZeitOn features are tailored to suit your work style. With its cloud-based architecture, you can access ZeitOn from anywhere, on any device, and at any time.',
            'With ZeitOn, you can say goodbye to the hassle of managing your time and absences manually. Try ZeitOn today and see how it can simplify your work life and help you be more productive.'
        ],
        videoId: 'bd2AHuQ4SoA'
    },
    {
        id: 'Calculation',
        tittle: 'Calculation Tools - The Ultimate Building Calculation Companion',
        description: [
            'Calculation Tools is a powerful application designed to provide you with all the calculations you need for your building project. With Calculation Tools, you can easily calculate heating loss, cooling loss, water needs, waste water, and more.',
            'This intuitive tool is easy to use and provides you with accurate calculations that you can rely on. Whether you are an architect, engineer, or building contractor, Calculation Tools is the perfect companion for all your building calculation needs.',
            'Calculation Tools is designed to be versatile and flexible, allowing you to input your project specifications and generate accurate calculations quickly. With Calculation Tools, you can save time and streamline your workflow, ensuring that your projects are completed on time and within budget.',
            'This application is designed to be user-friendly, with a clean and intuitive interface that makes it easy to navigate and find the calculations you need. With its advanced features, Calculation Tools is the ultimate building calculation tool that you can rely on for all your projects.',
            'Say goodbye to the hassle of manual calculations and try Calculation Tools today. With its powerful features and accuracy, you can be confident that your building projects are in good hands.'
        ],
        videoId: 'IWqPFUTgTLg'
    }
];

export function ReturnFullBlogHTML(bodyText: string) {
    var beforeBody = `<!DOCTYPE html>
    <html>
    <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>

            h1 {
                font-size: 18px;
            }

            h2 {
                font-size: 16px;
            }
            
            h3 {
                font-size: 14px;
            }
            
            h4 {
                font-size: 12px;
            }

            p {
                font-size: 16px;
            }

            li {
                font-size: 16px;
            }

            blockquote {
                font-size: 16px;
                margin:20px 10px auto !important;
                font-style:italic;
                color: #555555;
                padding:1.2em 20px 1.2em 55px !important;
                border-left:8px solid #FF8136;
                background:#F6F6F6;
                position: relative !important;
            }

            blockquote::before{
                font-family:Arial;
                content: "\\201C";
                color:#FF8136;
                font-size:4em !important;
                position: absolute !important;
                left: 10px;
                top:-10px;
              }
              
            @media only screen and (min-width: 660px) {
    
                h1 {
                  font-size: 22px;
                }
    
                h2 {
                  font-size: 20px;
                }
                
                h3 {
                  font-size: 18px;
                }
                
                h4 {
                  font-size: 14px;
                }
    
                p {
                  font-size: 16px;
                }

                li {
                    font-size: 16px;
                }

                blockquote {
                    font-size: 16px;
                }
            }

            @media only screen and (min-width: 1500px) {
    
                h1 {
                  font-size: 28px;
                }
    
                h2 {
                  font-size: 24px;
                }
                
                h3 {
                  font-size: 20px;
                }
                
                h4 {
                  font-size: 16px;
                }
    
                p {
                  font-size: 18px;
                }

                li {
                    font-size: 16px;
                }

                blockquote {
                    font-size: 18px;
                }
            }

            @media only screen and (min-width: 2000px) {
                p {
                  font-size: 22px;
                }

                blockquote {
                    font-size: 22px;
                }
            }
        </style>
    </head>
        <body>`

    var afterBody = `</body>
    </html>`

    return beforeBody + bodyText + afterBody
}

//Slice:
export function handleFulfilled(state: any, actionData: any): any {
    state.isLoading = false;
    state.error = '';
    return actionData;
}
export function handleRejected(state: any, action: RejectedValue) {
    state.isLoading = false;
    state.error = action?.message ?? 'Error in application';
    state.statusCode = action.status?.toString() ?? 'Code is not valid';
}
export function handlePending(state: any) {
    state.isLoading = true;
}

export const WebShopBimRefreshToken = "webShopBimRefreshToken";
export const WebShopBimTAccessToken = "webShopBimTAccessToken";

export const ResponsiveWidthProfile = "w-[100%]"
export const TableDataWidth = "w-[50px]"

export const textShadowStyle = { textShadow: '0px 3px 3px rgba(18, 18, 18, 0.20)' };

export const isValidBase64Image = (str: string | null | undefined) => {
    if (!str) {
        return false;
    }
    return /^data:image\/(png|jpg|jpeg|gif|webp);base64,/.test(str);
};