import { Navigate, Route, Routes } from 'react-router-dom'
import { LogIn } from "../features/Authentification/Pages/logIn";
import { Register } from "../features/Authentification/Pages/register";
import { ForgotPassword } from "../features/Authentification/Pages/forgotPassword";
import { ResetPassword } from "../features/Authentification/Pages/resetPassword";
import { ActivateAccount } from "../features/Authentification/Pages/activateAccount";
import { CreateUserData } from "../features/Accounts/Pages/createUserData";
import { TransactionStatusPage } from '../features/CommonPages/statusPage';
import { InitActivateAccount } from '../features/Authentification/Pages/initActivateAccount';
import { AboutPage } from '../MainPages/AboutPage';
import { BlogPage } from '../MainPages/BlogPage';
import { FAQPage } from "../MainPages/FAQPage";
import { ProfilePage } from "../MainPages/ProfilePage";
import { HomePage } from "../MainPages/HomePage";
import SingleBlogPage from '../UIComponents/Blogs/singleBlogPage';
import AllBlogPostsPage from '../UIComponents/Blogs/allBlogPostsPage';
//import TermsAndConditionsPage from '../MainPages/TermsAndConditions';
import CreateBlogPage from '../UIComponents/Blogs/CreateBlogPage';
//import PrivacyAndPolicy from '../MainPages/PrivacyAndPolicy';
import SSOPage from '../UIComponents/FAQs/ssoPage';
import { TGAConceptPage } from '../UIComponents/Products/tgaConceptPage';
import { CalculationToolsPage } from '../UIComponents/Products/calculationToolsPage';
import PortalPage from '../MainPages/PortalPage';
import CompanyPortalPage from '../UIComponents/Portal/CompanyPortalPage';
import CreateItemsPage from '../UIComponents/Portal/CreateItemsPage';
import Imprint from '../MainPages/Imprint';

export function AppRoutes() {
    return (
        <div>
            <Routes>
                <Route path='/login' element={<LogIn />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgotPassword' element={<ForgotPassword />} />
                <Route path='auth/resetPassword' element={<ResetPassword />} />
                <Route path='auth/activateAccount' element={<ActivateAccount />} />
                <Route path='auth/initActivateAccount' element={<InitActivateAccount />} />
                <Route path='/createUserData' element={<CreateUserData />} />
                <Route path='/transactionStatusPage' element={<TransactionStatusPage />} />
                <Route path='/aboutPage' element={<AboutPage />} />
                <Route path='/blogPage' element={<BlogPage />} />
                <Route path='/singleBlogPage' element={<SingleBlogPage />} />
                <Route path='/allBlogPostsPage' element={<AllBlogPostsPage />} />
                <Route path='/createBlogPage' element={<CreateBlogPage />} /> 
                <Route path='/faqPage' element={<FAQPage />} />
                <Route path='/profilePage' element={<ProfilePage />} /> 
                <Route path='/termsAndConditions' element={<Navigate to="/" replace /> } />
                <Route path='/privacyAndPolicy'  element={<Navigate to="/" replace /> } />
                <Route path='/imprint' element={<Imprint />} />
                <Route path='/singleSignOn' element={<SSOPage />} />
                <Route path='/productsPage'  element={<Navigate to="/productsPage/tgaconcept" replace /> } />
                <Route path='/productsPage/tgaconcept' element={<TGAConceptPage />} />
                <Route path='/productsPage/calculationtools' element={<CalculationToolsPage />} />
                <Route path='/portal' element={<PortalPage />} />
                <Route path="/portal/:companyName" element={<CompanyPortalPage />} />
                <Route path='/portal/createItems/:companyName' element={<CreateItemsPage />} />
                <Route path='/' element={<HomePage />} />
                <Route path='*' element={<Navigate to="/" replace /> } />
            </Routes>
        </div>
    );
}